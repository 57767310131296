import {useEffect} from "react";
import "./Home.css";
import PagePresenter from "./page/PagePresenter";
import Slideshow from "./Slideshow";
import "./slideshows.css";
import Popup from "@unclejust/amoge-client/src/components/Popup";
import PromotionPresenter from "./PromotionPresenter";

export default function Home() {
   const initTwitter = () => {
      (window as any).twttr = (function (d, s, id) {
         let js, fjs = d.getElementsByTagName(s)[0], t = (window as any).twttr || {};
         if (d.getElementById(id)) return t;
         js = d.createElement(s);
         js.id = id;
         js.src = "https://platform.twitter.com/widgets.js";
         fjs.parentNode.insertBefore(js, fjs);

         t._e = [];
         t.ready = function (f) {
            t._e.push(f);
         };

         return t;
      }(document, "script", "twitter-wjs"));
   }

   useEffect(() => {
      initTwitter();
   }, [])

   return <PagePresenter>
      <Popup/>
      <PromotionPresenter/>
      <main>
         <div className="splash">
            <Slideshow id="home"/>
         </div>
         <div>
            <div className="promo-video-container">
               <div className="promo-video-text">
                  <h1>Welcome to the world of Love the Ball</h1>
                  A great way for the world's next generation to enjoy and embrace an assortment of sporting activities.
               </div>
               <div className="promo-video">
                  <iframe src="https://www.youtube.com/embed/Z8rAx4iPqVU" frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen/>
               </div>
               <div style={{clear: "both"}}/>
            </div>
            <div className="columns">
               <div className="testimonials-container">
                  <div className="testimonial">
                     <div className="testimonial-content">
                        <div className="testimonial-quote">
                           "Enzo has had a <span className="highlight">wonderful</span> week, hope to send him to the
                           next camp."
                        </div>
                        <div className="testimonial-author">
                           GAVIN MURTON, ENZO'S DAD
                        </div>
                     </div>
                  </div>
                  <div className="testimonial">
                     <div className="testimonial-content">
                        <div className="testimonial-quote">
                           "The academy is <span className="highlight">enjoyable</span> and very
                           <span className="highlight">professional</span>. Mark Beard gets the best out of all of us"
                        </div>
                        <div className="testimonial-author">
                           OLLIE GILL, ACADEMY CAPTAIN
                        </div>
                     </div>
                  </div>
                  <div className="testimonial">
                     <div className="testimonial-content">
                        <div className="testimonial-quote">
                           "Just wanted to say a big thank you, you did a great job.
                           David is talking about it and I have had parents tell me today how much their children
                           enjoyed themselves.
                           One boy described it as <span className="highlight">'the best ever party'</span>.
                           Your company logo hits the nail on the head because these kids sure do <span
                             className="highlight">'love the ball'</span>"
                        </div>
                        <div className="testimonial-author">
                           AIMEE GRIPPMAN, DAVID'S MUM
                        </div>
                     </div>
                  </div>
                  <div className="testimonial">
                     <div className="testimonial-content">
                        <div className="testimonial-quote">
                           "Great bunch of guys <span className="highlight">passionate about coaching</span>.
                           We look forward to 'Love The Ball' Wednesday sessions at Coombe Hill Infants' School."
                        </div>
                        <div className="testimonial-author">
                           MISS LILLY, TEACHING ASSISTANT
                        </div>
                     </div>
                  </div>
                  <div className="testimonial">
                     <div className="testimonial-content">
                        <div className="testimonial-quote">
                           "5-star. Great coaches - <span className="highlight">boys and girls</span>
                           of all ages would love their sessions - <span className="highlight">fun</span> had by all."
                        </div>
                        <div className="testimonial-author">
                           DARREN MANNING, CROYGAS FC
                        </div>
                     </div>
                  </div>
               </div>
               <div className="twitter-container">
                  <div className="twitter">
                     <a className="twitter-timeline" data-height="600" data-theme="dark" data-link-color="#ee1b24"
                        href="https://twitter.com/_LoveTheBall?ref_src=twsrc%5Etfw">Tweets by _LoveTheBall</a>
                  </div>
               </div>
            </div>
         </div>
      </main>
   </PagePresenter>
}